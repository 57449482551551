import styled from 'styled-components'

const Container = styled.div`
    display: flex;
    flex-direction: column;
    background-color: white;

    color: ${({theme}) => theme.textColor};
    label {
        margin-bottom: 5px;
        font-weight: bold;
    }
    width: 100%;
    height: ${({theme}) => theme.height};
`

const InputContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;

    border: 1.25px solid;
    border-color: ${({theme}) => theme.borderColor};
    border-radius: 3px;

    height: 100%;
    padding: 6px;

    svg {
        margin-right: 10px;
        margin-left: 5px;
        color: ${({theme}) => theme.iconColor}
    }

    input {
        outline: none;
        border: 0.5px solid transparent;
        height: 100%;
        width: 100%;

        font-size: 1.1em;

        color: ${({theme}) => theme.textColor};

        ::placeholder {
            color: ${({theme}) => theme.placeHolderColor};
            opacity: 1;
            font-size: 1.15rem;
        }
    }
`

export { Container, InputContainer }