import { produce } from 'immer';

const initialState = {
  id: 0,
  nome: '',
  empresa_padrao: '',
  vendedor: '',
  id_vendedor: '',
  token: '',
  loading: false,
  logado: false,
};

export default function usuario(state = initialState, action) {
  switch (action.type) {
    case '@usuario/LOGIN_SUCESSO':
      return produce(state, (draft) => {
        const {
          id,
          nome,
          empresa_padrao,
          vendedor,
          id_vendedor,
          token,
        } = action.payload;

        return {
          ...draft,
          id,
          nome,
          empresa_padrao,
          vendedor,
          id_vendedor,
          token,
          loading: false,
          logado: true,
        };
      });

    case '@usuario/LOGIN_FALHA':
      return produce(state, () => {
        return initialState;
      });

    case '@usuario/LOGOUT':
      return produce(state, () => {
        return initialState;
      });
    default:
      return state;
  }
}
