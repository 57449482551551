import styled from "styled-components";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    height: 100vh;
    min-height: 100vh;

    width: 100%;


    h4 {
        margin: 0;
        font-size: 1.25em;
        max-width: 70%;
        margin: 20px 0px 10px 0px;
        color: #3D3D46;
    }

    h3 {
        margin: 10px 0px;
    }

    .camera {
        width: 95%;
    }
    
    .buttons {
        display: flex;
        flex-direction: row;
        
        margin-top: 10px;

        > button:first-child {
            margin-right: 10px;
        }
    }

    .bottomFixed {
        position: fixed;
        background-color: white;
        bottom: 0px;
        width: 100%;

        padding: 10px;
    }
`

export { Container }