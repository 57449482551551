import { all } from 'redux-saga/effects';

import usuario from './usuario/sagas';
import produtosBase from './produtosBase/sagas';
import carrinho from './carrinho/sagas';

export default function* rootSaga() {
  return yield all({
    usuario,
    produtosBase,
    carrinho,
  });
}
