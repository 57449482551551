// React
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

// Components
import BackgroundWithContent from "@/components/background"
import TInput from "@/components/elements/tinput"
import TButton from "@/components/elements/button";
import { Container, ButtonContainer } from "./styled"

// Libs
import { useDispatch, useSelector } from 'react-redux';
import { FaUser, FaLock } from "react-icons/fa";
import { loginFalha, loginSucesso } from "@/store/modules/usuario/actions";
import { motion, motionValue } from 'framer-motion'

import { userLogin, authToken } from "@/services/lib/login";

import storage from "redux-persist/lib/storage";
import Modal from "@/components/modal";

export default function Login() {
    const loginRef = useRef<HTMLInputElement>(null);
    const passwordRef = useRef<HTMLInputElement>(null);
    // dispatch
    const dispatch = useDispatch();
    const navigate = useNavigate()
    // campos de input
    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');
    
    // estados do modal
    const [modal, setModal] = useState(false);
    const [modalLoading, setModalLoading] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [modalButton, setModalButton] = useState(false);

    // logado
    const [logado, setLogado] = useState(false);
    const { logado: logadoState, token } = useSelector((state: any) => state.usuario);
  
    // Chamado quando o componente é criado.
    useEffect(() => {
      (async() => {
        await validarToken()
      })()
    }, []);

    // Função responsavel por verificar o login do usuario, realizando a validação do token.
    async function validarToken() {
      if (!token) {
        return;
      }
      
      setModal(true);
      setModalLoading(true);
      setModalMessage('Verificando login...');
      // Tentativa de validação de login
      const response = await authToken(token)

      if(response.errorID !== undefined) {
        dispatch(loginFalha());
        return;
      }

      // Em caso de sucesso monta o objeto do redux;
      const {
        pk_id: id,
        ds_login: nome,
        fk_emppadrao: empresa_padrao,
        ds_vendedor: vendedor,
        fk_vendedor: id_vendedor,
      } = response;

      const usuario_retorno = {
        id,
        nome,
        empresa_padrao,
        vendedor,
        id_vendedor,
        token,
      };
      
      dispatch(loginSucesso(usuario_retorno));
      navigate('/principal')

      setModalLoading(false);
      setModal(false);
    }
  
    // Função executada quando o usuario tenta se logar.
    async function handleLoginSubmit() {

      // Ambienta o modal inicialmente
      setModal(true);
      setModalLoading(false);
      setModalButton(false);

      // Tentativa de login
        // Verifica os dados preenchidos
      if (!login || !password) {
        setModal(false);
        return;
      }
  
      // Realiza o login na API
      setModalLoading(true);
      setModalMessage('Entrando...');

      const response = await userLogin(login, password)
      console.log(response)
      if (response.errorID !== undefined) {
        setModalMessage(`Erro ${response.errorID} - ${response.msg}`);
        dispatch(loginFalha());
        setModalLoading(false);
        setModalButton(true);
        loginRef.current?.focus();
        return;
      }

      // Limpa os campos de login
      setLogin('');
      setPassword('');

      // Em caso de sucesso monta o objeto do redux;
      const {
          pk_id: id,
          ds_login: nome,
          fk_emppadrao: empresa_padrao,
          ds_vendedor: vendedor,
          fk_vendedor: id_vendedor,
          authtoken: token,
      } = response;

      const usuario_retorno = {
        id,
        nome,
        empresa_padrao,
        vendedor,
        id_vendedor,
        token,
      };

      dispatch(loginSucesso(usuario_retorno));
      setModal(false);
      navigate('/principal')
    }
  
    // Função executada quando o usuario toca em configurar
    async function handleConfigurar() {
      const baseURL = await storage.getItem('baseURL');
      if (baseURL) {
        if(window.confirm('Você já possui uma URL configurada, deseja ainda sim abrir a pagina de Configuração?')) {
          navigate('/configuracao');
        }
      } else {
        navigate('/configuracao');
      }
    }

    return (
        <BackgroundWithContent>
            <Modal 
              showModal={modal}
              onClose={() => setModal(false)}
              text={modalMessage}
              loading={modalLoading}
              isButton={modalButton}
            />
            <Container>
                <img src="/src/assets/images/logo.png" alt="" />
                <TInput 
                    title="Usuário"
                    icon={<FaUser />}
                    placeholder="Digite seu usuário"
                    value={login}
                    onChange={(e: any) => setLogin((e.target.value).toUpperCase())}
                    styles={{
                      height: "55px"
                    }}

                    ref={loginRef}
                />
                <TInput 
                    title="Senha"
                    icon={<FaLock />}
                    placeholder="Sua senha secreta"
                    inputType="password"
                    value={password}
                    onChange={(e: any) => setPassword(e.target.value)}
                    styles={{
                      height: "55px"
                    }}

                    ref={passwordRef}
                />
                <ButtonContainer>
                    <TButton 
                        title="Login"
                        styles={{
                            bgColor: "#6D77F0",
                            textColor: "#FBFFFD",
                            height: "45px"
                        }}

                        onClick={handleLoginSubmit}
                    />
                    <TButton 
                        title="Configurar"
                        styles={{
                            bgColor: "#FA5C7C",
                            textColor: "#FBFFFD",
                            height: "45px"
                        }}

                        
                        onClick={handleConfigurar}
                    />
                </ButtonContainer>
            </Container>
        </BackgroundWithContent>
    )
}