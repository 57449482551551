
import styled from 'styled-components'

const Container = styled.div`
    display: flex;
    flex-direction: column;

    .fixed {
        position: fixed;
        width: 100%;
        background-color: white;
    }

    .boxContainers {
        padding: 0px 10px;
        margin: 10px 0px;
        padding-top: 120px;

        > div {
            margin: 10px 0px;

            :first-child {
                margin-top: 0px;
            }

            :last-child {
                margin-bottom: 0px;
            }
        }
    }

    .searchBox {
        display: flex;
        justify-content: center;
        align-items: center;

        height: 50px;
        padding: 10px;
        margin: 10px 10px 0px 10px;
    
        border-radius: 2.5px;
        background-color: #EAEBED;

    }

`

export { Container }