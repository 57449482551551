import styled from "styled-components";

const Container = styled.div`
    height: 100vh;

    .cabecalho {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        
        padding: 10px 10px 0px 10px;

        .comanda {
            display: flex;
            flex-direction: row;

            > label {
                margin-right: 5px;
            }
        }
        
        label {
            font-weight: bold;
            margin-bottom: 5px;
        }
    }

    .searchBox {
        display: flex;
        justify-content: center;
        align-items: center;

        height: 56px;
        padding: 10px;
        margin: 0px 10px 0px 10px;
    
        border-radius: 2.5px;
        background-color: #EAEBED;

    }

    .categorias {
        display: flex;
        flex-direction: row;
        background-color: #a8a8a8;

        margin: 10px 10px 0px 10px;

        padding: 1px;

        > div {
            margin-right: 1px;
        }

        overflow-y: auto;
    }

    .produtos {
        margin: 0px 10px;

        > div {
            margin: 10px 0px;
        }
    }

    .produtos, .empty {
        padding-top: 265px;
        padding-bottom: 95px;
    }

    .empty {
        font-weight: bold;
        text-align: center;
    }

    .fixed {
        background-color: white;
        position: fixed;
        width: 100%;
        top: 0;
    }

    .bottomFixed {
        background-color: white;
        position: fixed;
        width: 100%;
        bottom: 0;

        

        padding: 15px;
    }

    .destaque {
        color: #727CF5;
        text-decoration: underline;
    }
`

export  { Container }