import styled from "styled-components";

const Container = styled.div`
    padding: 10px;
    .infos {
        display: flex;
        flex-direction: column;

        > div {
            display: flex;
            flex-direction: row;
            align-items: center;
         

            label {
                font-weight: bold;
                margin-right: 5px;
            }

            p {
                margin: 0;
            }
        }

        .noValue {
            text-transform: uppercase;
        }
    }

    .contador {
        display: flex;
        flex-direction: column;

        margin-top: 10px;
        text-align: center;
        font-weight: bold;

        .count {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            h4 {
                margin: 0;
            }

            button {
                width: 45px;
                height: 45px;      
            }
        }
    }

    .total {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        label {
            margin-right: 5px;
        }

        p {
            font-weight: 100;
            margin: 0;
        }
    }

    .detalhes {
        display: flex;
        flex-direction: column;
        padding: 0px 5px;

        label {
            font-weight: bold;
            margin: 5px 0px;
        }

        textarea {
            resize: none;
            outline: none;
            height: 100px;
            width: 100%;

            padding-left: 5px;

            font-family: 'Roboto';
            font-size: 1em;
            color: black;
            text-transform: capitalize;
            
            ::placeholder {
                margin-left: 5px;
            }
        }
    }

    .complementos {
        margin-top: 10px;
    }

    .bottomFixed {
        bottom: 0;
        right: 0;
        left: 0;

        padding: 0px 10px 10px 10px;
        
        background-color: white;
        position: fixed;
        width: 100%;
    }

    .complementosList {
        background-color: #EAEBED;
        font-size: 0.85em;
        padding-left: 5px;

        :first-child {
            padding-top: 5px;
        }

        :last-child {
            padding-bottom: 5px;
        }
    }
`

export { Container }