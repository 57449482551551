import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Container } from "./styled";

import * as CarrinhoActions from '@/store/modules/carrinho/actions'
import ComplementoLista from "./components/complemento";
import NavBar from "@/components/navbar";
import TButton from "@/components/elements/button";

export default function Complementos() {
    const navigate = useNavigate()

    const dispatch = useDispatch();
    const produtoSelecionado = useSelector(
      (state: any) => state.carrinho.produto_selecionado
    );

    const [complementos, setComplementos] = useState<any>([]);
  
    useEffect(() => {
      setComplementos(produtoSelecionado?.complementos);
    }, [produtoSelecionado]);
  
    function handleComplemento(id: any) {
      setComplementos(
        complementos?.map((item: any) => {
          let { marcado } = item;
          if (item.id === id) {
            marcado = !marcado;
          }
          return {
            ...item,
            marcado,
          };
        })
      );
    }
  
    function handleOk() {
      dispatch(CarrinhoActions.alterarComplementos(complementos));
      navigate('/escolherProduto');
    }
  
    return (
        <div>
            <NavBar 
                title="Complementos"
                navigateTo="/escolherProduto"    
            />
            <Container>
                <h4> {produtoSelecionado?.modelo} </h4>
                <div className="complementos">
                    <h4> Padrão </h4> 
                    <div>
                        {complementos?.map((item: any, index: any) => {
                            return item.is_padrao && (
                                <ComplementoLista 
                                    key={item.id_complemento}
                                    item={item}
                                    onChange={() => handleComplemento(item.id)}
                                    marcado={item.marcado}
                                />
                            )
                
                        })}
                    </div>
                </div>
                <div className="complementos">
                    <h4> Opcionais </h4> 
                    <div>
                        {complementos?.map((item: any, index: any) => {
                                return !item.is_padrao && (
                                    <ComplementoLista 
                                        key={item.id_complemento}
                                        item={item}
                                        onChange={() => handleComplemento(item.id)}
                                        marcado={item.marcado}
                                    />
                            )
                        })}
                    </div>
                </div>
                <div className="bottomFixed">
                    <TButton 
                        title={"Ok"}
                        styles={{
                            textColor: "white"
                        }}
                        onClick={handleOk}
                    />
                </div>
            </Container>
        </div>
    )
}