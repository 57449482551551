import produce from 'immer';

const initialState = {
  loading: false,
  data_carga: '',
  categorias: {},
};

export default function produtosBase(state = initialState, action) {
  switch (action.type) {
    case '@produtosBase/CARREGAR_REQUEST':
      return produce(state, (draft) => {
        return {
          ...draft,
          loading: true,
          categorias: {},
        };
      });

    case '@produtosBase/CARREGAR_SUCESSO':
      return produce(state, (draft) => {
        return {
          ...draft,
          loading: false,
          data_carga: action.payload.data_carga,
          categorias: action.payload.categorias,
        };
      });

    case '@produtosBase/CARREGAR_FALHA':
      return produce(state, (draft) => {
        return {
          ...draft,
          loading: true,
          categorias: {},
        };
      });

    default:
      return state;
  }
}
