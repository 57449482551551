import styled from "styled-components";

const Container = styled.div`
    > h4 {
        margin: 20px;
    }

    .complementos {
        padding: 0px 10px;
        > div {
            padding: 10px;
            > :first-child {
                margin-bottom: 10px;
            }
        }
        h4 {
            margin: 0px;
        }
    }

    .bottomFixed {
        padding: 10px;
        bottom: 0;
        position: fixed;
        background-color: white;
        width: 100%;
    }
`

export { Container }