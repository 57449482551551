
import NavBar from "@/components/navbar"
import ProductBox from "@/components/productBox"
import { Container } from "./styled"

import cores from '@/styles/colors'
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { formatarDataHora, formatarPreco } from "@/util/format";
import { getHistoricoCompleto } from "@/services/lib/historico";
import TInput from "@/components/elements/tinput";

import { FaSearch } from 'react-icons/fa'
import Modal from "@/components/modal";
import { useNavigate } from "react-router-dom";
import BackgroundWithContent from "@/components/background";

export default function Historico() {
    const navigate = useNavigate()

    const [procura, setProcura] = useState('');
    const [comandas, setComandas] = useState([]);
    const [comandasBase, setComandasBase] = useState([]);
    const usuario = useSelector((state: any) => state.usuario);
    const [modal, setModal] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [modalLoading, setModalLoading] = useState(false);
    const [modalButton, setModalButton] = useState(false);

    useMemo(() => {
        setComandas(
          comandasBase.filter((item: any) => {
            return item.nome.includes(procura.toUpperCase());
          })
        );
    }, [comandasBase, procura]);

    useEffect(() => {
        carregarHistorico();
    }, []);

    function handleZoomComanda(codigo_carteirinha: any) {
        navigate('/consulta', { state: codigo_carteirinha });
    }

    async function carregarHistorico() {
        // Ambienta o modal inicialmente
        setModal(true);
        setModalLoading(true);
        setModalButton(false);
    
        // Tentativa de login
        // Realiza o login na API
        setModalMessage('Buscando comandas...');

        const response = await getHistoricoCompleto(usuario.token)
        console.log(response[0])
        // Trata o retorno

        if (response.errorID !== undefined) {
            setModalMessage('Verifique o usuário e senha e tente novamente.');
            setModalLoading(false);
            setModalButton(true);
            navigate(-1)
            return;
        }
    
        // Em caso de sucesso monta o objeto do redux;
        const comandasLista = response.map((comanda: any, idx: any) => {
            return {
            id: idx + 1,
            codigo_carteirinha: comanda.ds_codigo,
            nome: comanda.ds_cliente,
            valor_total: comanda.vl_total,
            valor_total_formatado: formatarPreco(comanda.vl_total),
            data_ultimo_pedido: formatarDataHora(comanda.dh_ultpedido),
            };
        });
        setComandasBase(comandasLista);
        setComandas(comandasLista);
        setModal(false);
    }

    return (
        <BackgroundWithContent>
            <Container>
                <Modal
                    loading={modalLoading}
                    showModal={modal}
                    text={modalMessage}
                    isButton={modalButton}
                    onClose={() => {
                        setModal(false);
                    }}
                />
                <div className="fixed">
                    <NavBar
                        title="Histórico de comandas"
                        navigateTo="/principal"
                    />
                    <div className="searchBox">
                        <TInput 
                            icon={<FaSearch />}
                            placeholder="Digite o nome do sócio"
                            styles={{
                                height: "35px"
                            }}
                            
                            value={procura}
                            onChange={(e: any) => setProcura(e.target.value)}
                        />
                    </div>
                </div>
                <div className="boxContainers">
                    {comandas.length > 0 && 
                        comandas.map((item: any, index: number) => {
                            return  (
                                <ProductBox
                                    key={item.index}
                                    nrComanda={item.codigo_carteirinha}
                                    socio={item.nome}
                                    valorTotal={item.valor_total_formatado}
                                    ultimoPedido={item.data_ultimo_pedido}
                
                                    styles={{
                                        bgColor: `${index % 2 == 0 ? cores.zebrados.cor1 : cores.zebrados.cor2}`
                                    }}

                                    onClick={() => handleZoomComanda(item.codigo_carteirinha)}
                                />
                            )
                        })
                    }
                </div>
            </Container>
        </BackgroundWithContent>
    )
}