export function selecionar(produto) {
  return {
    type: '@carrinho/SELECIONAR',
    produto,
  };
}

export function adicionar(produto, detalhes = '') {
  return {
    type: '@carrinho/ADICIONAR',
    produto,
    detalhes,
  };
}

export function removerProduto(id) {
  return {
    type: '@carrinho/REMOVER_PRODUTO',
    id,
  };
}

export function limparCarrinho() {
  return {
    type: '@carrinho/LIMPAR_CARRINHO',
  };
}

export function limparComanda() {
  return {
    type: '@carrinho/LIMPAR_COMANDA',
  };
}

export function alterarQuantidade(quantidade) {
  return {
    type: '@carrinho/ALTERAR_QUANTIDADE',
    quantidade,
  };
}

export function alterarComplementos(complementos) {
  return {
    type: '@carrinho/ALTERAR_COMPLEMENTOS',
    complementos,
  };
}
export function atualizarPreco() {
  return {
    type: '@carrinho/ATUALIZAR_PRECO',
  };
}

export function calcularPrecoTotal() {
  return {
    type: '@carrinho/CALCULAR_PRECOTOTAL',
  };
}

export function alterarSocio(socio) {
  return {
    type: '@carrinho/ALTERAR_SOCIO',
    socio,
  };
}

export function limparSocio() {
  return {
    type: '@carrinho/LIMPAR_SOCIO',
  };
}

export function alterarLocal(local) {
  return {
    type: '@carrinho/ALTERAR_LOCAL',
    local,
  };
}
