import React from "react";
import { Container } from "./styled";

import Cores from '@/styles/colors' 
const colors = Cores.categorias

interface CategoriaType {
    item?: any,
    marcado?: boolean,
    onClick?: React.MouseEventHandler<any>
}


export default function Categoria({
    item,
    marcado,
    onClick
}: CategoriaType) {
    return (
        <Container 
            theme={!marcado ? {bgColor: colors.cor1} : {bgColor: colors.cor2}}
            onClick={onClick}
        >
            {item.nome}
        </Container>
    )
}