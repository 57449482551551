import createAxiosInstance, { promiseTimeout } from "../axiosClient"

export async function userLogin(login: string, password: string) {
    const axiosClient = await createAxiosInstance()
    const request = axiosClient.post('/login', {login, password})
    return promiseTimeout(request)

}

export async function authToken(token: string) {
    const axiosClient = await createAxiosInstance()
    const request = axiosClient.get('/auth/login/', {
        headers: { 'x-access-token': token }
    })

    
    return promiseTimeout(request)

}
