import TInput from "@/components/elements/tinput";
import NavBar from "@/components/navbar";
import { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Container } from "./styled";

import * as CarrinhoActions from '@/store/modules/carrinho/actions'
import { incluirVenda } from "@/services/lib/vendas";
import ProdutoDetalhado from "@/components/produtoDetalhado";
import TButton from "@/components/elements/button";
import Modal from "@/components/modal";
import BackgroundWithContent from "@/components/background";


export default function Carrinho() {
    const navigate = useNavigate()

    const [local, setLocal] = useState('');
    const [botaoModal, setBotaoModal] = useState(false);
    const [loadingModal, setLoadingModal] = useState(false);
    const [sucesso, setSucesso] = useState(false);
    const dispatch = useDispatch();
    const carrinho = useSelector((state: any) => state.carrinho);
    const usuario = useSelector((state: any) => state.usuario);
  
    const [modal, setModal] = useState(false);
    const [modalMessage, setModalMessage] = useState('');


    
    useMemo(() => {
      dispatch(CarrinhoActions.alterarLocal(local));
    }, [local]);
  
    async function handleFinalizarPedido() {
      setModal(true);
      setLoadingModal(true);
      setBotaoModal(false);
      setModalMessage('Gravando pedido, por favor aguarde...');
      const pedidos = [
        {
          nrComanda: carrinho.socio.codigo_carteirinha,
          idVendedor: usuario.id_vendedor,
          vendedor: usuario.vendedor,
          localEntrega: carrinho.localEntrega,
          itens: carrinho.produtos.map((produto: any) => {
            const complemento = produto.complementos.reduce(
              (acumulador: any, item: any) => {
                if (
                  (item.is_padrao && !item.marcado) ||
                  (!item.is_padrao && item.marcado)
                ) {
                  return `${acumulador}, ${item.is_padrao ? 'sem ' : ''}${
                    item.nome
                  }`;
                }
                return acumulador;
              },
              ''
            );
            // Monta os detalhes do pedido.
            const detalhesComplemento = complemento.substring(1).trim()
              ? complemento.substring(1).trim()
              : '';
            const detalhesProduto = produto.detalhes.trim()
              ? produto.detalhes.trim()
              : '';

            let detalhesPedido = '';
            // Caso possua detalhes de complemento e detalhes de produto, concatena os dois separando por virgula.
            if (detalhesComplemento && detalhesProduto) {
              detalhesPedido = `${detalhesComplemento}, ${detalhesProduto}`;
            } else {
              detalhesPedido = detalhesComplemento || detalhesProduto;
            }

            return {
              idProduto: produto.sku,
              nomeProd: produto.modelo,
              unidade: produto.unidade,
              quantidade: produto.quantidade,
              vlUnitario: produto.preco,
              vlTotal: produto.valor_total,
              detalhePed: detalhesPedido,
            };
          }),
        },
      ];
      const response = await incluirVenda(pedidos, usuario.token)

      setLoadingModal(false);
      if (response.errorID !== undefined) {
        setSucesso(false);
        setModalMessage(response.message);
        setBotaoModal(true);
        setLoadingModal(false);
        return;
      }
      
      setSucesso(true);
      setModalMessage(`
Pedido incluso com sucesso.
`);
      setBotaoModal(true);
      setLoadingModal(false);
      return;
    }
  
    function handleRemoverProduto(item: any) {
        if(window.confirm(`Deseja realmente remover o produto ${item.quantidade} x ${item.modelo} ?`)) {
            dispatch(CarrinhoActions.removerProduto(item.id));
        }
    }
  
    function handleModal() {
      if (sucesso) {
        dispatch(CarrinhoActions.limparComanda());
        navigate('/principal');
      }
      setModal(false);
    }

    return (
      <BackgroundWithContent>
        <Container>
            <Modal 
                buttonText="Ok"
                showModal={modal}
                isButton={botaoModal}
                loading={loadingModal}
                text={modalMessage}
                onClose={handleModal}
            />
            <div className="fixedTop">
                <NavBar 
                    title="Carrinho"
                    navigateTo="/catalogo"
                />
                <div className="infos">
                    <div>
                        <label> Comanda Nº</label>
                        <p> {carrinho?.socio.codigo_carteirinha} </p>
                    </div>
                    <div>
                        <label> Sócio </label>
                        <p> {carrinho?.socio.nome} </p>
                    </div>
                    <div>
                        <label> Local</label>
                        <TInput 
                            placeholder="Digite o local"
                            styles={{
                                height: "35px"
                            }}
                            value={local}
                            onChange={(e: any) => setLocal(e.target.value)}
                        />
                    </div>
                </div>
                <div className="selectedItens"> Itens selecionados: <span> {carrinho.produtos.length} </span></div>
                {carrinho.produtos.length > 0 ? (
                  <div>
                    <div className="produtos">
                      {carrinho.produtos.map((item: any, index: any) => {
                          return (
                            <ProdutoDetalhado
                              key={index}
                              item={item}
                              isRemover={true}
                              removeFunction={() => handleRemoverProduto(item)}
                            />
                          )
                      })}
                    </div>
                    <div className="bottomFixed">
                      <div className="totalContainer">
                        <label> Valor total </label>
                        <p> {carrinho.valor_total_formatado} </p>
                      </div>
                      <TButton 
                        title={"Finalizar pedido"}
                        styles={{
                          textColor: "white"
                        }}
                        onClick={handleFinalizarPedido}
                      />
                    </div>
                  </div>
                ) : (
                  <div className="empty"> seu carrinho está vazio </div>
                )}
            </div>
        </Container>
      </BackgroundWithContent>
    )
}