const Colors = {
    containers: {
        bgColor: "#FFFFFF",
        text: "#3D3D46",
        bolderText: "#727CF5",
        fontSize: "16px"
    },
    buttons: {
        bgColor: "#6D77F0",
        textColor: "#CFCED7",
        fontSize: '1em'
    },
    input: {
        textColor: "#3D3D46",
        borderColor: "#6C757D",
        bgColor: "#FFFFFF",
        labelColor: "#3D3D46",
        placeHolderColor: "#6C757D",
        iconColor: "#6C757D",
        fontSize: 100
    },
    modal: {
        fundo: '#FFFFFF',
        fundotitulo: '#727CF5',
        titulo: '#FFFFFF',
        texto: '#3D3D46',
        fundobotao: '#727CF5',
        textobotao: '#FFFFFF',
        loadingColor: "#727CF5"
    },
    zebrados: {
        cor1: "#EAEBED",
        cor2: "#E3E3FF"
    },
    categorias: {
        cor1: "#EEEEEE",
        cor2: "#CDCDFF"
    }
}

export default Colors