import styled from 'styled-components'

const LabelWithValueContainer = styled.div`
    display: flex;
    flex-direction: ${({theme}) => theme.display};
    color: #3D3D46;

    font-size: 1.25em;

    label {
        margin-right: 5px;
        font-weight: bold;
    }

    p {
        margin: 0px;
    }
`

export { LabelWithValueContainer }