import { HashRouter } from 'react-router-dom'

import { AnimatePresence } from 'framer-motion'
import AnimatedRoutes from './AnimatedRoutes'


export default function Router() {
    return (
        <AnimatePresence exitBeforeEnter>
            <HashRouter>
                <AnimatedRoutes />
            </HashRouter>
        </AnimatePresence>
    )
} 