
import { ReactElement } from 'react'
import { LabelWithValueContainer } from './labelWithValue-styled'

interface LabelTypes {
    label?: ReactElement | string,
    content?: string,
    display?: 'column' | 'row'
    styles ?: LabelStyles
}

interface LabelStyles {
    display?: 'column' | 'row'
}

const labelDefaultStyles = {
    display: 'row'
}

const labelDefaultProps = {
    label: "",
    content: "",
    styles: labelDefaultStyles
}

function LabelWithValue({
    label, 
    content,
    styles
}: LabelTypes) {
    return (
        <LabelWithValueContainer theme={styles}>
            <label> {label} </label>
            <p> {content} </p>
        </LabelWithValueContainer>
    )
}

LabelWithValue.defaultProperties = labelDefaultProps

export default LabelWithValue