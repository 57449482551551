
import { ReactNode } from "react";

import { Container } from './styled'
import { FaArrowLeft } from 'react-icons/fa'
import { useNavigate } from "react-router-dom";

interface NavProps {
    navigateTo: any,
    title: string,
    onUnmount ?: any
}


export default function NavBar({
    title, 
    navigateTo,
    onUnmount
}:NavProps) {
    const navigate = useNavigate()

    const handleNavigate = () => {
        navigate(navigateTo);
        if (onUnmount)
            onUnmount()
        ;
    }

    return (
        <Container>
            <FaArrowLeft onClick={handleNavigate}/>
            <p> {title} </p>
        </Container>
        
    )
}