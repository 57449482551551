import { formatarPreco } from "@/util/format";
import React from "react";
import { Container } from "./styled";

import Cores from '@/styles/colors'

interface Produto {
    item: {
        id: number,
        sku: string,
        modelo: string,
        quantidade: number
        preco: number
    },
    index: number,
    onClick: React.MouseEventHandler<any>
}

export default function ProdutoBox({
    item,
    index,
    onClick
}: Produto) {
    return (
        <Container 
            theme={{
                bgColor: (index % 2 ? Cores.zebrados.cor2 : Cores.zebrados.cor1)
            }}
            onClick={onClick}
        >
            <div className="title"> {item.sku} - {item.modelo} </div>
            <div className="price"> {formatarPreco(item.preco)} </div>
        </Container>
    )
}