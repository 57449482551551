import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'

export default function PersistReducers (reducers) {
  return persistReducer(
    {
      key: 'mobile_vixencomanda',
      storage: storage,
      whitelist: ['usuario', 'produtosBase'],
    },
    reducers
  );

};
