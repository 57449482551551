import createAxiosInstance, { promiseTimeout } from "../axiosClient"

export async function cancelarProduto(idProduto: number, token: string) {
    const axiosClient = await createAxiosInstance()
    const request = axiosClient.post(
        `/validar/cancelar/${idProduto}`, 
        undefined, 
        {
            headers: {'x-access-token': token}
        }
    )
    return promiseTimeout(request)
}

export async function transfComanda(userID: any, origem: any, destino: any, usuario: any, token: string) {
    const axiosClient = await createAxiosInstance()
    const request = axiosClient.post('/validar/transfcomanda', { 
        userID, origem, destino, usuario}, {
            headers: {'x-access-token': token}
        }
    )
    return promiseTimeout(request)
}


export async function getComandas(codigo: any, token: string) {
    const axiosClient = await createAxiosInstance()
    const request = axiosClient.get(`/buscar/pedido/${codigo}`, {
        headers: { 'x-access-token': token },
    })
    return promiseTimeout(request)
}