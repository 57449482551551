
import TButton from '@/components/elements/button'
import { Container } from './styled'

import { IoIosClose } from 'react-icons/io'
import { FaCircle } from 'react-icons/fa'

import Cores from '@/styles/colors'
import { MouseEventHandler } from 'react'

interface Comanda {
    item: any,
    isRemover: boolean,
    removeFunction?: MouseEventHandler<any>
}

export default function ProdutoDetalhado({
    item,
    removeFunction,
    isRemover
}: Comanda) {
    return (
        <Container theme={{
            bgColor: item.id % 2 ? Cores.zebrados.cor2 : Cores.zebrados.cor1
        }}>
            <div className='topInfos'>
                <div> {item.quantidade} x {item.modelo} </div>
                {isRemover && (
                    <TButton 
                        onClick={removeFunction}
                        icon={<IoIosClose size={25} color="white"/>}
                        styles={{
                            bgColor: "#727CF5"
                        }}
                    />
                )}
            </div>
            {Array.isArray(item.complementos) && (
               <div className='complementos'>
                 {item.complementos.map((comp: any) => {
                    if ( (comp.is_padrao && !comp.marcado) || (!comp.is_padrao && comp.marcado)) {
                        console.log(comp)
                        return (
                            <div>
                                <FaCircle size={7.5}/>
                                <div> {comp.is_padrao ? 'sem' : ''} {comp.nome} </div>
                            </div>
                        );
                    } else
                        return <></>;
                })}
               </div>
            )}
            {item.detalhes.length > 0 && (
                <div className='detalhes'>
                    <h4> Detalhes </h4>
                    <div> {item.detalhes} </div>
                </div>
            )}
            <div className='valores'> 
                <div className='valor'> Valor </div>
                <div> {item.valor_total_formatado} </div>
            </div>
        </Container>
    )
}