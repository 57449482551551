import styled from 'styled-components'

const Container = styled.button`
    background-color: ${({theme}) => theme.bgColor};
    color: ${({theme}) => theme.textColor};

    font-family: "Roboto";
    font-size: ${({theme}) => theme.fontSize};
    font-weight: bolder;

    display: flex;
    flex-direction: row;

    align-items: center;
    justify-content: center;

    border: 0px solid transparent;
    border-radius: 2.5px;

    width: 100%;
    height: ${({theme}) => theme.height};

    .disabled {
        background-color: gray;
    }

    p {
        margin: 0;
        max-width: 90%;
    }
`

export { Container }