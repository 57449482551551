import { ReactElement, ReactNode } from "react";
import { Container } from "./styled";

import Cores from "@/styles/colors"
const colors = Cores.buttons

type ButtonTypes = {
    title?: ReactElement | string,
    icon?: ReactElement,
    disabled?: boolean,
    onClick?: React.MouseEventHandler<any>,
    styles?: StylesTypes
}

interface StylesTypes {
    bgColor?: string,
    textColor?: string,
    fontSize ?: string,
    height ?: string,
}

const defaultInputProps = {
    onClick: () => {},
    disabled: false,
    styles: {
        bgColor: colors.bgColor,
        textColor: colors.textColor,
        fontSize: colors.fontSize,
        height: "45px",
    }
}

const defaultDisabledButton: StylesTypes = {
    bgColor: "#E2E6E9",
    textColor: "#A0A7AC",
    fontSize: colors.fontSize,
    height: "45px"
}

function TButton({
    title,
    icon,
    disabled,
    onClick,
    styles, 
}: {children?: ReactNode} & ButtonTypes) {
    
    const style = {...defaultInputProps.styles, ...styles}

    return (
        <Container 
            theme={!disabled ? style : defaultDisabledButton} 
            onClick={!disabled ? onClick : () => {}}
        >
            {icon}
            {title && <p> {title} </p>}
        </Container>
    )
}

TButton.defaultProps = defaultInputProps;

export default TButton

