import TButton from "@/components/elements/button";
import NavBar from "@/components/navbar";
import { Container } from "./styled";

import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import * as CarrinhoActions from '@/store/modules/carrinho/actions'
import { formatarPreco } from "@/util/format"
import Modal from "@/components/modal";
import BackgroundWithContent from "@/components/background";


export default function EscolherProduto() {
    const navigate = useNavigate()

    const [detalhes, setDetalhes] = useState('');
    const [modal, setModal] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
  
    const dispatch = useDispatch();
    const produtoSelecionado = useSelector(
      (state: any) => state.carrinho.produto_selecionado
    );
  
    function handleAumentarQuantidade() {
      switch (produtoSelecionado.quantidade) {
        case 0.5:
          dispatch(CarrinhoActions.alterarQuantidade(1));
          break;
        default:
          dispatch(
            CarrinhoActions.alterarQuantidade(produtoSelecionado.quantidade + 1)
          );
          break;
      }
    }
  
    function handleDiminuirQuantidade() {
      switch (produtoSelecionado.quantidade) {
        case 1:
          dispatch(CarrinhoActions.alterarQuantidade(0.5));
          break;
        default:
          dispatch(
            CarrinhoActions.alterarQuantidade(produtoSelecionado.quantidade - 1)
          );
          break;
      }
    }
  
    function handleAdicionar() {
      dispatch(CarrinhoActions.adicionar(produtoSelecionado, detalhes));
      setModal(true);
      setModalMessage('Produto adicionado no carrinho.');
    }
  
    function handleComplementos() {
      navigate('/complementos');
    }

    return (
        <BackgroundWithContent>
            <NavBar 
                title="Escolha do produto"
                navigateTo="/catalogo"
            />
            <Modal 
                showModal={modal}
                text={modalMessage}
                onClose={() => {
                  setModal(false);
                  navigate('/catalogo');
                }}
            />
            <Container>
                <div className="infos">
                    <div>
                        <label> Código </label>
                        <p> {produtoSelecionado.sku} </p>
                    </div>
                    <div className="noValue">
                        <label> {produtoSelecionado.modelo} </label>
                    </div>
                    <div>
                        <label> Preço </label>
                        <p> {formatarPreco(produtoSelecionado.preco)} </p>
                    </div>
                </div>
                <div className="contador">
                    <label> Quantidade </label>
                    <div className="count">
                        <TButton 
                            title={"-"}
                            styles={{
                                textColor: "white"
                            }}
                            onClick={handleDiminuirQuantidade}
                        />
                        <h4> {produtoSelecionado?.quantidade.toString()} </h4>
                        <TButton 
                            title={"+"}
                            styles={{
                                textColor: "white"
                            }}
                            onClick={handleAumentarQuantidade}
                        />
                    </div>
                    <div className="total">
                        <label>Total </label>
                        <p> {produtoSelecionado?.valor_total_formatado} </p>
                    </div>
                </div>
                <div className="complementos">
                    <TButton
                        title="Complementos"
                        onClick={handleComplementos}
                        disabled={!produtoSelecionado?.complementos.length}
                        styles={{
                            textColor: "white"
                        }}
                    />
                </div>
                {produtoSelecionado.complementos.length > 0 && (
                    <div className="teste">
                        {produtoSelecionado.complementos.map((item: any, index: any) => {
                            console.log(item.is_padrao && !item.marcado)
                            console.log(!item.is_padrao && item.marcado)
                            return (
                                ((item.is_padrao && !item.marcado) ||
                                (!item.is_padrao && item.marcado)) && (
                                    <div key={index} className="complementosList"> 
                                        {`${item.is_padrao ? 'sem' : ''} ${item.nome}`}
                                    </div>
                                )
                            )
                        })}
                    </div>
                )}
                <div className="detalhes">
                    <label> Detalhes </label>
                    <textarea 
                        placeholder="Detalhes do produto" 
                        value={detalhes} 
                        onChange={(e: any) => setDetalhes(e.target.value)}
                    /> 
                </div>
                <div className="bottomFixed"> 
                    <TButton
                        title={"Adicionar"}
                        styles={{
                            textColor: "#FFFFFF"
                        }}

                        onClick={handleAdicionar}
                    />
                </div>
            </Container>
        </BackgroundWithContent>
    
    )
}