import styled from "styled-components";

const Container = styled.button`
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        height: 40px;
        width: 40px;

        /* #517fA4 */
        background-color: ${({theme}) => theme.bgColor};

        outline: none;
        border: 1px solid transparent;
        border-radius: 50px;

    .badge {
        position: absolute;
        top: 0;
        right: 0;

        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        
        /* green */
        background-color: ${({theme}) => theme.badgeColor};
        border-radius: 50px;
        color: white;
        font-weight: 100;

        width: 17.5px;
        height: 17.5px;
    }

    p {
        align-self: center;
        margin: 0px;
    }
`

export { Container }