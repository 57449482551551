import styled from 'styled-components'

const Container = styled.div`
    align-items: left;
    align-self: center;

    margin-top: 20px;

    width: 90%;
    height: 100vh;

    .inputContainer {
        display: flex;
        flex-direction: column;
        width: 100%;

        color: ${({theme}) => theme.textColor};
        background-color: ${({theme}) => theme.bgColor};

        label {
            color: ${({theme}) => theme.labelColor};
            margin-bottom: 5px;
            font-weight: bold;
        }

        textarea {
            outline: none;
            resize: none;
            border-color: ${({theme}) => theme.borderColor};
            
            font-family: Arial, Helvetica, sans-serif;
            font-size: 1.25em;
            padding: 5px;

            line-break: strict;

            height: 125px;
        }
    }

    .version {
        text-align: center;
        font-weight: bold;

        margin-top: 12.5px;
    }
`

const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    width: 100%;
    margin-top: 2vh;

    button:first-child {
        margin-right: 3vw;
    }
`

export { Container, ButtonContainer }