
import BackgroundWithContent from '@/components/background';
import TButton from '@/components/elements/button';
import TInput from '@/components/elements/tinput';
import LerCarteirinha from '@/components/lerCarteirinha';
import Modal from '@/components/modal';
import NavBar from '@/components/navbar'
import ProdutoDetalhado from '@/components/produtoDetalhado';
import { cancelarProduto, getComandas, transfComanda } from '@/services/lib/comanda';
import { formatarPreco } from '@/util/format';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Container } from './styled'

export default function ConsultarComanda() {
    const location: any = useLocation();
    const navigate = useNavigate()

    const [codigoCarteirinha, setCodigoCarteirinha] = useState('');
    const [codigoTransferencia, setCodigoTransferencia] = useState<number>();
    // estados do modal
    const [modal, setModal] = useState(false);
    const [modalLoading, setModalLoading] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [modalButton, setModalButton] = useState(false);
    const [modalTitulo, setModalTitulo] = useState('');
    let [comanda, setComanda] = useState({
      codigo_carteirinha: '',
      nome: '',
      valor_total: 0,
      valor_total_formatado: '',
      data_ultimo_pedido: '',
      produtos: [],
    });
    const [resfreshPage, setRefreshPage] = useState("");
    const usuario = useSelector((state: any) => state.usuario);
  

    useEffect(() => {
        carregarComanda(location?.state ?? '');
    }, []);

    async function carregarComanda(codigo = '') {
        if (!codigo) return

        setModal(true);
        setModalLoading(true);
        setModalButton(false);
        setModalTitulo('');
        setModalMessage('Carregando informações da comanda...');

        const response = await getComandas(codigo, usuario.token)

        if (response.errorID !== undefined) {
            setModalTitulo('Erro ao carregar comanda');
            setModalMessage(`${response.errorID} - ${response.msg}`);
            setModalLoading(false);
            setModalButton(true);
            return;
        }

        const data = response;
        
        
        const comandaRetorno = {
          codigo_carteirinha: data.ds_codigo,
          nome: data.ds_cliente,
          valor_total: data.vl_total,
          valor_total_formatado: formatarPreco(data.vl_total),
          data_ultimo_pedido: data.dh_ultpedido,
          produtos: data.itens.map((prod: any, idx: any) => {
            return {
              id: idx + 1,
              pk_id: prod.pk_id,
              modelo: prod.ds_modelo,
              quantidade: prod.qt_movimento,
              detalhes: prod.ds_detalheitem,
              valor_total_formatado: formatarPreco(prod.vl_pretot),
            };
          }),
        };
        
        setCodigoCarteirinha(codigo);
        setComanda(comandaRetorno);
        setModalMessage('');
        setModalLoading(false);
        setModal(false);
    }

    function scannearCarteirinha(codigo: any) {
      carregarComanda(codigo);
    }
    
    async function RemoverProdutoView(item: any){
        setModal(false);
        setModal(true);
        setModalLoading(true);
        setModalButton(false);
        setModalMessage('Excluindo item da comanda...');

        const resp = await cancelarProduto(item.pk_id, usuario.token)

        if (resp.errorID !== undefined){
          setModalTitulo('');
          setModalTitulo('Erro ao cancelar item da comanda');
          setModalMessage(`${resp.errorID} - ${resp.message}`);
          setModalLoading(false);
          setModalButton(true);
          return
        }
  
        setModalTitulo('');
        setModal(false);

        let comandaUpdate = comanda;
        const indice = comandaUpdate.produtos.findIndex(
            (produto: any) => produto.id === item.id
        );
        if (indice >= 0) {
            comandaUpdate.produtos.splice(indice, 1);
        };
    
        setComanda(comandaUpdate);
        setRefreshPage(Math.random().toString()) // Variável de estado para forçar refresh.;

    }
    
    async function transferirComanda (comandaOrigem: any, comandaDestino: any){
        setModal(false);
        setModal(true);
        setModalLoading(true);
        setModalButton(false);
        setModalMessage('Transferindo comanda...');
  
        const resp = await transfComanda(usuario.id, comandaOrigem, comandaDestino, usuario.id, usuario.token)

        if (resp.errorID !== undefined){
          setModalTitulo('');
          setModalTitulo('Erro ao transferir comanda');
          setModalMessage(`${resp.errorID} - ${resp.message}`);
          setModalLoading(false);
          setModalButton(true);
          return;
        }
  
        setModalTitulo('Sucesso');
        setModalMessage('Comanda transferida com sucesso');
        setModal(true);

        setCodigoTransferencia(0)
        carregarComanda(comandaDestino)
    }
  
    function handleRemoverProduto (item: any){
        if(window.confirm(`Deseja realmente remover o produto ${item.quantidade} x ${item.modelo} ?`)) {
            RemoverProdutoView(item);
        }
    }

    function handleTransferirComanda(){
        if(!codigoTransferencia)
            return
        ;
        if (window.confirm('Deseja realmente transferir a comanda: ' + codigoCarteirinha + ' para a comanda  ' + codigoTransferencia + '?')) {
            transferirComanda(codigoCarteirinha, codigoTransferencia);
        }
    }

    if (!codigoCarteirinha) {
        return (
          <BackgroundWithContent>
            <div>
              {modal ? (
                <Modal
                  loading={modalLoading}
                  showModal={modal}
                  title={modalTitulo}
                  text={modalMessage}
                  isButton={modalButton}
                  onClose={() => {
                    setModal(false);
                  }}
                />
              ) : (
                <div>
                    <LerCarteirinha funcaoScan={scannearCarteirinha} />
                </div>
              )}
            </div>
          </BackgroundWithContent>
        )
    }

    return (
        <BackgroundWithContent>
            <Container>
                <div className='fixedTop'>
                    <NavBar
                        title='Consulta de comanda'
                        navigateTo={-1}
                    />
                    <div className='comandaInfos'>
                        <div>
                            <label> Comanda Nº </label>
                            <div className='destaque'> {codigoCarteirinha} </div>
                        </div>
                        <div>
                            <label>Sócio</label>
                            <div> {comanda.nome} </div>
                        </div>
                    </div>

                    {comanda.produtos && (
                        <div>
                            <div className='valorParcial info'>
                                <label>Valor parcial</label>
                                <div> {comanda.valor_total_formatado} </div>
                            </div>
                            <div className='qtdItens info'>
                                <label>Quantidade de itens</label>
                                <div> {comanda.produtos.length} </div>
                            </div>
                        </div>
                    )}
                </div>
    
                    {comanda.produtos.length > 0 && (
                        <div className='produtos'>
                            {comanda.produtos.map((item, index) => {
                                return (
                                    <ProdutoDetalhado 
                                        key={index}
                                        item={item}
                                        isRemover={true}
                                        removeFunction={() => handleRemoverProduto(item)}
                                    />
                                )
                            })}
                        </div>
                    )}

                <div className="fixedBottom">
                    <label> Transferência de comanda </label>
                    <TInput 
                        placeholder='Número da nova comanda'
                        value={codigoTransferencia}
                        onChange={(e: any) => setCodigoTransferencia(e.target.value)}
                        styles={{
                            height: "35px"
                        }}
                        inputType="number"
                    />
                    <TButton 
                        title={"Transferir comanda >>"}     
                        onClick={handleTransferirComanda}
                        styles={{
                            textColor: "#FFFFFF",
                            height: "35px"
                        }}
                    />
                </div>
            </Container>
        </BackgroundWithContent>
    )
}