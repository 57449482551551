import styled from "styled-components";

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    white-space: nowrap;
    height: 55px;
    font-size: 0.85em;

    background-color: ${({theme}) => theme.bgColor};
  
    padding: 0px 15px;

    font-weight: bold;
`

export { Container }