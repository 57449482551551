import { Container } from "./styled";


export default function Badge({
    icon,
    badgeColor,
    bgColor,
    onClick,
    value
}: any) {
    return (
        <Container 
            theme={{
                badgeColor,
                bgColor
            }}
            onClick={onClick}
        >

            {icon}
            <div className="badge"> 
                <p> {value} </p>
            </div>    

        </Container>
    )
}