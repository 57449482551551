import { put, all, takeLatest } from 'redux-saga/effects';

import { atualizarPreco, calcularPrecoTotal } from './actions';

function* atualizarPrecoCarrinho() {
  yield put(atualizarPreco());
}

function* calcularPrecoTotalCarrinho() {
  yield put(calcularPrecoTotal());
}

export default all([
  takeLatest('@carrinho/SELECIONAR', atualizarPrecoCarrinho),
  takeLatest('@carrinho/ALTERAR_QUANTIDADE', atualizarPrecoCarrinho),
  takeLatest('@carrinho/ADICIONAR', calcularPrecoTotalCarrinho),
  takeLatest('@carrinho/REMOVER_PRODUTO', calcularPrecoTotalCarrinho),
  takeLatest('@carrinho/LIMPAR_COMANDA', calcularPrecoTotalCarrinho),
]);
