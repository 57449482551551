import BackgroundWithContent from "@/components/background";
import TButton from "@/components/elements/button"
import Modal from "@/components/modal";
import { getProdutos } from "@/services/lib/produtos";
import { carregarBaseFalha, carregarBaseSucesso } from "@/store/modules/produtosBase/actions";
import { logout } from "@/store/modules/usuario/actions";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import LabelWithValue from "./labelWithValue/labelWithValue"
import { Container, ButtonContainer } from './styled'


export default function HomePage() {
    const dispatch = useDispatch();
    const navigate = useNavigate()
    

    const { data_carga } = useSelector((state: any) => state.produtosBase);
    const usuario = useSelector((state: any) => state.usuario);
  
    const [modal, setModal] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [modalTitulo, setModalTitulo] = useState('Aviso');
    const [modalLoading, setModalLoading] = useState(false);
    const [modalButton, setModalButton] = useState(false);
    const [modalButtonText, setModalButtonText] = useState('OK');
  
    useEffect(() => {
      carregarProdutosBaseInicial();
    }, []);

    async function carregarProdutosBase(dataCarga = '') {
        setModal(true);
        setModalLoading(true);
        setModalButton(false);
        setModalTitulo('');
        setModalMessage('Carregando lista de produtos...');
  
        if (!usuario.token || !usuario.empresa_padrao) {
          setModalTitulo('Erro na carga de produtos');
          setModalMessage('Token ou empresa padrão não preenchidos.');
          return;
        }
  
        const response = await getProdutos(usuario.empresa_padrao, usuario.token)
        // Trata o retorno

        if (response.errorID !== undefined) {
            setModalTitulo('Erro na carga de produtos');
            setModalMessage(`${response.errorID} - ${response.msg}`);

            dispatch(carregarBaseFalha());

            setModalLoading(false);
            setModalButton(true);
          return;
        }

        const categorias: any[] = response.map((cat: any) => {
          return {
            id: cat.pk_id,
            nome: cat.ds_categoria,
            produtos: cat.produtos.map((prod: any, idx: any) => {
              return {
                id: idx + 1,
                sku: prod.pk_id,
                modelo: prod.ds_modelo,
                preco: prod.vl_preco,
                unidade: prod.ds_unidade,
                complementos: prod.complementos.map((com: any, idx_complemento: any) => {
                  return {
                    id: idx_complemento + 1,
                    id_complemento: com.pk_id,
                    nome: com.ds_complemento,
                    is_padrao: com.tg_itempadrao === 1,
                  };
                }),
              };
            }),
          };
        });
  
        // Cria a categoria TODOS
        const categoriaTodos: {id: string, nome: string, produtos: any[]} = {
          id: 'VIXENCOMANDA_TODOS',
          nome: 'TODOS',
          produtos: [],
        };
  
        // Varre todas as categorias e todos os produtos para adicionar na categoria todos.
        categorias.forEach((categoria: any) => {
          categoria.produtos.forEach((produto: any) => {
            categoriaTodos.produtos.push(produto);
          });
        });
  
        // Adiciona categoria todos no inicio da lista
        categorias.unshift(categoriaTodos);
        dispatch(carregarBaseSucesso(categorias, dataCarga));
        setModalTitulo('Aviso')
        setModalMessage(`Produtos carregados com sucesso!`);
        setModalLoading(false);
        setModalButton(true);
    }
  
    // Verifica se deve ser carregado a base de produtos inicialmente
    async function carregarProdutosBaseInicial() {
      const dataCarga = new Date().toLocaleDateString();
      console.log(dataCarga)
      console.log(data_carga)

      if (dataCarga === data_carga) {
        return;
      }
  
      await carregarProdutosBase(dataCarga);
    }
  
    function handleLancarComanda() {
      navigate('/catalogo');
    }
  
    function handleConsultarComanda() {
      navigate('/consulta');
    }
  
    function handleListarComandas() {
      navigate('/historico');
    }
  
    function handleRecarregarProdutos() {
        if(window.confirm('Deseja recarregar todos os produtos e categorias?')) {
            carregarProdutosBase(new Date().toLocaleDateString());
        }
    }
  
    function handleSair() {
        if (window.confirm('Deseja realmente sair?')) {
            dispatch(logout())
            navigate('/')
        }
    }
  
    return (
      <BackgroundWithContent>
        <Container>
          <Modal 
              title={modalTitulo}
              buttonText={modalButtonText}
              loading={modalLoading}
              showModal={modal}
              text={modalMessage}
              isButton={modalButton}
              onClose={() => {
                setModal(false);
              }}
          />
            <div className="infos">
                <LabelWithValue 
                    label="Usuário"
                    content={usuario.nome}
                />
               <LabelWithValue 
                    label="Empresa"
                    content={usuario.empresa_padrao}
                />
                <LabelWithValue 
                    label="Vendedor"
                    content={usuario.vendedor}
                    styles={{
                        display: 'column'
                    }}
                />
            </div>
            <div className="optContainer">
                <TButton 
                title="Lançar comanda"
                styles={{
                        textColor: "white",
                        bgColor: "#6D77F0",
                        height: "20vh",
                        fontSize: "2em"
                    }}
                  onClick={() => handleLancarComanda()}
                />
                <TButton 
                    title={"Consultar comanda"}
                    styles={{
                        textColor: "white",
                        bgColor: "#FA5C7C",
                        height: "20vh",
                        fontSize: "2em"
                    }}
                    onClick={() => handleConsultarComanda()}
                />
                <TButton 
                    title={"Histórico"}
                    styles={{
                        textColor: "white",
                        bgColor: "#EBB007",
                        height: "20vh",
                        fontSize: "2em",
                    }}
                    onClick={() => handleListarComandas()}
                /> 
            </div>
            <ButtonContainer>
                <TButton 
                    title={"Recarregar produtos"}
                    styles={{
                        textColor: "white",
                        bgColor: "#6D77F0",
                        height: "50px",
                    }}
                    onClick={() => handleRecarregarProdutos()}
                /> 
                <TButton 
                    title={"Sair"}
                    styles={{
                        textColor: "white",
                        bgColor: "#FA5C7C",
                        height: "50px",
                    }}
                    onClick={() => handleSair()}
                /> 
            </ButtonContainer>
        </Container>
      </BackgroundWithContent>
    )
}