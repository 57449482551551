import styled from "styled-components";

const Container = styled.div`
    display: flex;
    flex-direction: column;

    width: 100%;
    height: 100vh;
    max-height: 100vh;

    .infos {
        display: flex;
        flex-direction: column;

        background-color: #EAEBED;
        padding: 10px;
        margin: 10px 10px 0px 10px;

        div {
            max-width: 80%;
            margin-bottom: 2.5px;

            :last-child {
                label {
                    margin-bottom: 2.5px;
                }

                margin-bottom: 0px;
            }
        }
    }

    .optContainer {
        padding: 0px 10px;
        button {
            margin: 20px 0px;

            :last-child {
                margin: 0px;
            }
        }
    }
`

const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: auto;

    padding: 10px;

    button:first-child {
        margin-right: 3vw;
    }


`


export { Container, ButtonContainer }