import styled from "styled-components";

const Container = styled.div`
    display: flex;
    flex-direction: column;

    background-color: ${({theme}) => theme.bgColor};

    padding: 15px;
    border: 1px solid #bdbdbd;
    border-radius: 5px;

    .title {
        font-weight: bold;
        font-size: 0.9em;
    
        margin-bottom: 4px;
    }

    .price {
        font-size: 0.8em;
    }
`

export { Container }