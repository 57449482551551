import { useEffect, useRef, useState } from "react";
import NavBar from "../navbar";

import { QrReader } from 'react-qr-reader'
import { Container } from "./styled";

import { FaIdCard } from 'react-icons/fa'
import TInput from "../elements/tinput";
import TButton from "../elements/button";

interface CarteirinhaProps {
    funcaoScan: (codigo: string) => void;
}

export default function LerCarteirinha({
    funcaoScan
}: CarteirinhaProps) {
    const [codigoCarteirinha, setCodigoCarteirinha] = useState('')
    const alredyScanned = useRef(false)

    const onScannedCode = async(codigo: string) => {
        if (!alredyScanned.current) {
            alredyScanned.current = true
            funcaoScan(codigo)
        }
    }


    return (
        <Container>
            <NavBar
                title='Consulta de comanda'
                navigateTo={"/principal"}
            />
            <h4> Leia o código de barras da carteirinha </h4>
            <QrReader 
                onResult={async(result) => {
                    if (!!result) {
                        await onScannedCode(result.toString())
                    }
                }}
                constraints={{
                    facingMode: 'environment'
                }}
            />
            
            <div className="bottomFixed">
                <h3> Ou </h3>
                <TInput 
                    placeholder="Digite o numero da carteirinha"
                    icon={<FaIdCard size={35}/>}
                    value={codigoCarteirinha}
                    onChange={(e: any) => setCodigoCarteirinha(e.target.value)}
                />
                <div className="buttons">
                    <TButton 
                        title="Limpar"
                        styles={{
                            bgColor: "#FA5C7C",
                            textColor: "white"
                        }}
                        onClick={() => setCodigoCarteirinha('')}
                    />
                      <TButton 
                        title="Continuar"
                        styles={{
                            textColor: "white"
                        }}

                        onClick={() => onScannedCode(codigoCarteirinha)}
             
                    />
                </div>
            </div>
            
        </Container>
    )
}