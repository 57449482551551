import styled from 'styled-components'

const Container = styled.div`
    display: flex;
    flex-direction: column;

    background-color: ${({theme}) => theme.bgColor};
    color: #3D3D46;
    font-size: 18px;

    padding: 5px;

    div {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 2.5px;

        label {
            margin-right: 5px;
            font-weight: bold;
        }

        p {
            margin: 0;
            font-size: 18px;
        }
    }

    .destaque {
        color: #727CF5;
    }
`

export { Container }