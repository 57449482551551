import styled from 'styled-components'

const Container = styled.div`
    height: 100vh;

    .comandaInfos {
        display: flex;
        flex-direction: column;
        padding: 10px 5px;

        margin: 10px 10px 0px 10px;
        background-color: #EAEBED;
        font-size: 1.05em;

        > div {
            display: flex;
            flex-direction: row;

            > label {
                margin-right: 5px;
                font-weight: bold;
            }
        }
    }

    .destaque {
        color: #727CF5;
        font-weight: bold;
    }

    .info {
        display: flex;
        flex-direction: row;

        margin: 0px 10px;

        label {
            font-weight: bold;
        }
    }

    .valorParcial {
        justify-content: space-between;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .qtdItens {
        label {
            margin-right: 5px;
        }
    }

    .produtos {
        padding: 0px 10px;  
        padding-top: 220px;
        padding-bottom: 95px;

        > div {
            margin: 10px 0px;
            
            :first-child {
                margin-top: 0px;
            }
        }
    }

    .fixedTop {
        position: fixed;
        width: 100%;
        top: 0;

        background-color: white;
    }

    .fixedBottom { 
        display: flex;
        flex-direction: column;
        text-align: center;

        position: fixed;
        width: 100%;
        bottom: 0;
        background-color: white;
        
        padding: 10px;

        label {
            font-weight: bold;
        }

        button {
            margin-top: 5px;
            font-weight: 100;
        }
    }
`

export { Container }