import styled from 'styled-components'

const Container = styled.div`
    background-color: ${({theme}) => theme.bgColor};
    padding: 5px;

    .topInfos {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        width: 100%;

        font-size: 1.1em;
        font-weight: bolder;
        color: #3D3D46;

        button {
            max-width: 35px;
            max-height: 35px;
        }

        > div:first-child {
            max-width: 70%;
        }
    }

    .detalhes {
        h4 {
            margin: 0px 0px 5px 0px;
        }

        div {
            max-width: 80%;
        }
    }

    .valores {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        

        .valor {
            font-weight: bold;
        }
    }
    
    .complementos {
        font-size: 0.9em;

        > div {
            display: flex;
            flex-direction: row;
            align-items: center;

            > :first-child {
                margin: 0px 5px;
            }
        }
    }
`

export { Container }