import styled from 'styled-components'

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 90%;

    align-items: center;
    justify-content: center;
    align-self: center;

    height: 100vh;

    div:nth-child(2) {
        margin-bottom: 30px;
    }

    img {
        display: flex;
        height: auto;
        width: 300px;
        height: 100px;

        margin-bottom: 40px;
    }
`

const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    width: 100%;
    margin-top: 3.5vh;

    button:first-child {
        margin-right: 3vw;
    }
`

export { Container, ButtonContainer }