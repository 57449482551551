import BackgroundWithContent from "@/components/background";
import TButton from "@/components/elements/button";
import NavBar from "@/components/navbar";
import { useEffect, useState } from "react";
import storage from "redux-persist/lib/storage";

import { Container, ButtonContainer } from "./styled";

import Colors from "@/styles/colors";
import { useNavigate } from "react-router-dom";
import { testConn } from "@/services/lib/configuracao";
import Modal from "@/components/modal";

const cores = Colors.input


export default function Configuracao() {
    const [salvar, setSalvar] = useState(false);

    const [modal, setModal] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [modalLoading, setModalLoading] = useState(false);
    const [modalButtonText, setModalButtonText] = useState('OK');
    const [_loadingSalvar, setLoadingSalvar] = useState(false);

    const [URL, setURL] = useState('')

    const navigate = useNavigate()

    useEffect(() => {
        setSalvar(false);
    }, [URL]);

    useEffect(() => {
        (async() => {
            const baseURL = await storage.getItem('baseURL')
            if (!baseURL)
                setURL('http://ENDERECO:PORTA/app/comanda');
            else
                setURL(baseURL)
        })()
    }, [])

    async function handleTestar() {
        setModal(true);
        setModalLoading(true);
        // Verifica os dados preenchidos
        if (!URL) {
            setModalMessage('Preencha a url corretamente.');
            setModalLoading(false);
            return;
        }

        setModalMessage('Tentando conectar por favor aguarde...');
        setModalButtonText('Cancelar');
        const response = await testConn(`${URL}/teste/config`);
        console.log(response)

        if (response.errorID !== undefined) {
            setModalMessage(
                `Erro ao acessar a API, verifique os dados digitados e tente novamente.
                Código de erro ${response.errorID}
                Descrição de erro
                ${response.msg}`
            );
            setModalLoading(false);
            setModalButtonText('OK');
            return
        }

        setModalMessage('Conexão realizada com sucesso!');
        setSalvar(true);
        setModalButtonText('OK');
        setModalLoading(false);
        } 
    
      async function handleSalvar() {
        setLoadingSalvar(true);
        await storage.setItem('baseURL', URL);
        navigate('/');
        setLoadingSalvar(false);
      }

    return (
        <BackgroundWithContent>
            <Modal
              showModal={modal}
              onClose={() => setModal(false)}
              text={modalMessage}
              loading={modalLoading}
              buttonText={modalButtonText}
            />
            <NavBar 
                title="Configuração"
                navigateTo="/"    
            />
            <Container theme={cores}>
                <div className="inputContainer">
                    <label> URL Principal </label>
                    <textarea 
                        value={URL}
                        onChange={e => setURL(e.target.value)}
                    />
                </div>
                <ButtonContainer>
                    <TButton 
                        title="Salvar"
                        styles={{
                            bgColor: "#6D77F0",
                            textColor: "#FBFFFD",
                            height:"45px"
                        }}

                        disabled={!salvar}
                        onClick={handleSalvar}
                    />
                    <TButton 
                        title="Testar"
                        styles={{
                            bgColor: "#FA5C7C",
                            textColor: "#FBFFFD",
                            height:"45px"
                        }}

                        onClick={handleTestar}
                    />
                </ButtonContainer>
                <div className="version">
                    Versão 2.0.0
                </div>
            </Container>
        </BackgroundWithContent>
    )
}