import { Container } from './styled'

interface BoxProps {
    nrComanda: string,
    socio: string,
    valorTotal: string,
    ultimoPedido: string,
    onClick ?: React.MouseEventHandler<any>,
    styles: BoxStyles
}

interface BoxStyles {
    bgColor: string
}

export default function ProductBox ({
    nrComanda,
    socio,
    valorTotal,
    ultimoPedido,
    onClick,
    styles
}: BoxProps) {
    return (
        <Container theme={styles} onClick={onClick}>
            <div>
                <label> Comanda Nº </label>
                <p  className='destaque'> {nrComanda} </p> 
            </div>
            <div>
                <label> Sócio </label>
                <p> {socio} </p> 
            </div>
            <div>
                <label> Valor total </label>
                <p> {valorTotal} </p> 
            </div>
            <div>
                <label>Dh. ultimo pedido</label>
                <p> {ultimoPedido} </p> 
            </div>
        </Container>
    )
}