import { Routes, Route, useLocation } from 'react-router-dom'

import Login from '@/pages/login'
import Configuracao from './pages/configuracao'
import HomePage from './pages/homePage';
import Historico from './pages/dashboard/histórico';
import ConsultarComanda from './pages/dashboard/consulta';
import Catalogo from './pages/comanda/catalogo';
import EscolherProduto from './pages/comanda/escolherProduto';
import Complementos from './pages/comanda/complementos';
import Carrinho from './pages/comanda/carrinho';

export default function AnimatedRoutes() {
    const location = useLocation()
    return (
        <Routes location={location}  key={location.pathname}>
            <Route path='/' element={<Login />} />
            <Route path='/principal' element={<HomePage />} />
            <Route path='/configuracao' element={<Configuracao />} />
            <Route path='/historico' element={<Historico />} />
            <Route path='/consulta' element={<ConsultarComanda />} />
            <Route path='/catalogo' element={<Catalogo />} />
            <Route path='/escolherProduto' element={<EscolherProduto />} />
            <Route path='/complementos' element={<Complementos />} />
            <Route path="/carrinho" element={<Carrinho />} />
        </Routes>
    )
}