export function formatarPreco(valor = 0) {
  const valorFormatado = valor.toFixed(2).split('.');
  valorFormatado[0] = `R$ ${valorFormatado[0]
    .split(/(?=(?:...)*$)/)
    .join('.')}`;
  return valorFormatado.join(',');
}

export function formatarDataHora(data = '') {
  // Date example: 2022-07-22T14:28:00.000Z
  const splitedDateTime = new Date(data).toISOString().split('T')
  const a = splitedDateTime[0].split('-')
  const b = splitedDateTime[1].split(':')
  const formatedDate = `${a[2]}/${a[1]}/${a[0]} ${b[0]}:${b[1]}`
  return formatedDate
}