import axios from 'axios'
import { promiseTimeout } from '../axiosClient'

export function testConn(url: string) {
    try {
        const request = axios.get(url)
        return promiseTimeout(request)
    } catch(err) {
        return {
            msg: "",
            errorID: "",
            error: err
        }
    }
  }