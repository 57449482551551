import {forwardRef, ReactElement } from "react";
import { Container, InputContainer } from "./styled";

import Cores from "@/styles/colors"
const colors = Cores.input

interface InputTypes {
    title?: ReactElement | string,
    icon?: ReactElement,
    placeholder?: string,
    inputType?: string,
    value?: any,
    onChange?: any,
    styles?: StylesTypes
}

interface StylesTypes {
    textColor?: string,
    borderColor?: string,
    bgColor?: string,
    labelColor?: string,
    placeHolderColor?: string,
    iconColor?: string,
    height?: string
}

const defaultInputProps = {
    placeholder: "",
    inputType: "text",
    styles: {
        textColor: colors.textColor,
        borderColor: colors.borderColor,
        bgColor: colors.bgColor,
        labelColor: colors.labelColor,
        placeHolderColor: colors.placeHolderColor,
        iconColor: colors.iconColor,
        height: "45px"
    },
    ref: null
}

function InputNoRef({
    title,
    icon,
    placeholder,
    inputType,
    value,
    onChange,
    styles
}: InputTypes, ref: any) {
    const style = {...defaultInputProps.styles, ...styles}

    return (
        <Container theme={style}>
            {title && <label> {title} </label>}
            <InputContainer theme={style}>
                {icon}
                <input 
                    placeholder={placeholder} 
                    type={inputType}
                    ref={ref} 
                    value={value} 
                    onChange={onChange}
                />
                
                </InputContainer>
        </Container>
    )
}
const TInput = forwardRef(InputNoRef)

TInput.defaultProps = defaultInputProps


export default TInput
