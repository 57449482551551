import styled from "styled-components";

const Container = styled.div`
    background-color: #fbfbfb;
    font-weight: bolder;
    font-size: 0.9em;
    border-radius: 2.5px;

    padding: 5px;

    display: flex;
    flex-direction: row;
    align-items: center;

    border: 2px solid #ebebeb;

    input[type=checkbox] {
        width: 25px;
        height: 25px;
        
        margin-right: 10px;

        background-color: #d3d3d3;
    }
`

export { Container }