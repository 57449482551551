export function carregarBaseSucesso(categorias, data_carga) {
  return {
    type: '@produtosBase/CARREGAR_SUCESSO',
    payload: {
      data_carga,
      categorias,
    },
  };
}

export function carregarBaseFalha() {
  return {
    type: '@produtosBase/CARREGAR_FALHA',
    payload: {
      categorias: [],
    },
  };
}
