 
import { ReactNode } from 'react'
import { Container } from './styled'

import Cores from '@/styles/colors'
const colors = Cores.containers

export default function BackgroundWithContent({children}: {children: ReactNode}) {
    return (
        <Container 
            theme={colors.bgColor} 
        >
            {children}
        </Container>
    )
}