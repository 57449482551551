import { produce } from 'immer';
import { formatarPreco } from '@/util/format';

const initialState = {
  localEntrega: '',
  valor_total: 0,
  valor_total_formatado: '',
  socio: {
    id: 0,
    codigo_carteirinha: '',
    nome: '',
    empresa: '',
    data_abertura: null,
  },
  produto_selecionado: null,
  produtos: [],
};

export default function carrinho(state = initialState, action) {
  switch (action.type) {
    case '@carrinho/SELECIONAR':
      return produce(state, (draft) => {
        const produto = {
          ...action.produto,
          quantidade: 1,
        };
        produto.complementos = produto.complementos.map((item) => {
          return {
            ...item,
            marcado: item.is_padrao,
          };
        });
        draft.produto_selecionado = produto;
      });

    case '@carrinho/ADICIONAR':
      return produce(state, (draft) => {
        draft.produtos.push({
          ...action.produto,
          id: draft.produtos.length + 1,
          detalhes: action.detalhes,
        });
      });

    case '@carrinho/REMOVER_PRODUTO':
      return produce(state, (draft) => {
        const indice = draft.produtos.findIndex(
          (produto) => produto.id === action.id
        );
        if (indice >= 0) {
          draft.produtos.splice(indice, 1);
        }
      });

    case '@carrinho/LIMPAR_CARRINHO':
      return produce(state, (draft) => {
        draft.produtos = [];
      });

    case '@carrinho/LIMPAR_COMANDA':
      return produce(state, () => {
        return initialState;
      });

    case '@carrinho/REMOVER':
      return produce(state, () => {});

    case '@carrinho/ALTERAR_QUANTIDADE': {
      if (Number(action.quantidade) <= 0) {
        return state;
      }

      return produce(state, (draft) => {
        draft.produto_selecionado.quantidade = Number(action.quantidade);
      });
    }
    case '@carrinho/ALTERAR_COMPLEMENTOS': {
      const complementos = Array.isArray(action.complementos)
        ? action.complementos
        : [];
      return produce(state, (draft) => {
        draft.produto_selecionado.complementos = complementos;
      });
    }

    case '@carrinho/ATUALIZAR_PRECO': {
      return produce(state, (draft) => {
        draft.produto_selecionado.valor_total =
          draft.produto_selecionado.quantidade *
          draft.produto_selecionado.preco;

        draft.produto_selecionado.valor_total_formatado = formatarPreco(
          draft.produto_selecionado.valor_total
        );
      });
    }

    case '@carrinho/CALCULAR_PRECOTOTAL': {
      return produce(state, (draft) => {
        const valor_total = draft.produtos.reduce(
          (total, { valor_total: total_produto }) => total + total_produto,
          0
        );
        return {
          ...draft,
          valor_total,
          valor_total_formatado: formatarPreco(valor_total),
        };
      });
    }

    case '@carrinho/ALTERAR_SOCIO':
      return produce(state, (draft) => {
        draft.socio = action.socio;
      });

    case '@carrinho/LIMPAR_SOCIO':
      return produce(state, (draft) => {
        return {
          ...draft,
          socio: initialState.socio,
        };
      });

    case '@carrinho/ALTERAR_LOCAL':
      return produce(state, (draft) => {
        draft.localEntrega = action.local;
      });

    default:
      return state;
  }
}