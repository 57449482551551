import { Container } from "./styled";




export default function ComplementoLista({
    item,
    marcado,
    onChange
}: any) {
    return (
        <Container onClick={onChange}>
            <input 
                type="checkbox" 
                id={item.id_complemento} 
                checked={marcado}
                onChange={onChange}
            ></input>
            <label> {item.nome} </label>
        </Container>
    )
}