import { combineReducers } from 'redux';

import usuario from './usuario/reducer';
import produtosBase from './produtosBase/reducer';
import carrinho from './carrinho/reducer';

export default combineReducers({
  usuario,
  produtosBase,
  carrinho,
});
