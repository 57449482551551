import NavBar from "@/components/navbar";
import Badge from "./components/badge";
import { Container } from "./styled";

import { FaSearch, FaShoppingCart } from 'react-icons/fa'
import TInput from "@/components/elements/tinput";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useMemo, useState } from "react";

import * as CarrinhoActions from '@/store/modules/carrinho/actions';
import Categoria from "./components/categorias";
import ProdutoBox from "./components/produto";
import Modal from "@/components/modal";
import TButton from "@/components/elements/button";
import BackgroundWithContent from "@/components/background";
import LerCarteirinha from "@/components/lerCarteirinha";
import { getValidarCarteirinha } from "@/services/lib/carteirinha";

export default function Catalogo() {
    const dispatch = useDispatch();
    const navigate = useNavigate()

    const [procura, setProcura] = useState('');
    const [produtos, setProdutos] = useState([]);
    const usuario = useSelector((state: any) => state.usuario);
    const carrinho = useSelector((state: any) => state.carrinho);
    const categorias = useSelector((state: any) => state.produtosBase.categorias);
  
    // estados do modal
    const [modal, setModal] = useState(false);
    const [modalLoading, setModalLoading] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [modalButton, setModalButton] = useState(false);
    const [modalTitulo, setModalTitulo] = useState('');
  
    // Marca a primeira categoria
    const [categoriaSelecionada, setCategoriaSelecionada] = useState(
      categorias.length > 0 ? categorias[0].id : ''
    );
  
    useMemo(() => {
      const tempProdutos: any = [];
      // Carrega as categorias.
      let tempCategorias = categoriaSelecionada
        ? categorias.filter((cat: any) => cat.id === categoriaSelecionada)
        : categorias;
  
      // Caso a categoria não venha em formato de Array, seta como array
      tempCategorias = Array.isArray(tempCategorias)
        ? tempCategorias
        : [tempCategorias];
  
      // Itera pela lista de categorias
      tempCategorias.forEach((cat: any) => {
        // Itera pela lista de produtos da categoria
        cat.produtos.forEach((prod: any) => {
          tempProdutos.push({ ...prod, id: tempProdutos.length + 1 });
        });
      });
  
      // Altera a lista dos produtos, realizando o filtro digitado.
      setProdutos(
        tempProdutos.filter(
          (item: any) =>
            item.sku.toUpperCase().includes(procura.toUpperCase()) ||
            item.modelo.toUpperCase().includes(procura.toUpperCase())
        )
      );
    }, [categoriaSelecionada, categorias, procura]);
  
    function handleLimparCarrinho() {
      dispatch(CarrinhoActions.limparCarrinho());
    }
  
    async function validarCarteirinha(codigo = '') {
      // Ambienta o modal inicialmente
      setModal(true);
      setModalLoading(true);
      setModalButton(false);
      // Tentativa de login
      if (!codigo) {
        setModalLoading(false);
        setModalMessage('O código da carteirinha não pode ser vazio.');
        setModalButton(true);
        return;
      }
  
      setModalMessage(
        'Consultando informações da carteirinha por favor aguarde...'
      )
  
      const response = await getValidarCarteirinha(codigo, usuario.empresa_padrao, usuario.token)

      // Trata o retorno
      if (response.errorID !== undefined) {
        setModalTitulo('Erro na validação de carteirinha');
        setModalLoading(false);
        setModalMessage(`${response.errorID} - ${response.msg}`);
        setModalButton(true);

        return;
      }

      const data = response

      const socio = {
        id: data.pk_id,
        codigo_carteirinha: data.ds_codigo,
        nome: data.ds_cliente,
        empresa: data.fk_empresa,
        data_abertura: data.dh_abertura,
      };
      dispatch(CarrinhoActions.alterarSocio(socio));


      setModalMessage('');
      setModalButton(false);
      setModal(false);
    }
  
    function handleCarrinho() {
      navigate('/carrinho');
    }
  
    // Marca a categoria como selecionada.
    function handleSelecionarCategoria(item: any) {
      const { id: categoriaId } = categorias.find((cat: any) => cat.id === item.id);
  
      // Deseleciona a categoria.
      if (categoriaId === categoriaSelecionada) {
        setCategoriaSelecionada(categorias.length > 0 ? categorias[0].id : '');
        return;
      }
  
      // Seleciona a categoria.
      if (categoriaId) {
        setCategoriaSelecionada(categoriaId);
      }
    }
  
    function handleAdicionarCarrinho(produto: any) {
      dispatch(CarrinhoActions.selecionar(produto));
      navigate('/escolherProduto');
    }



    if (!carrinho.socio.codigo_carteirinha) {
      return (
        <BackgroundWithContent>
          <div>
            {modal ? (
              <Modal
                loading={modalLoading}
                showModal={modal}
                title={modalTitulo}
                text={modalMessage}
                isButton={modalButton}
                onClose={() => {
                  setModal(false);
                }}
              />
            ) : (
              <div>
                  <LerCarteirinha funcaoScan={validarCarteirinha} />
              </div>
            )}
          </div>
        </BackgroundWithContent>
      )
    }

    return (
      <BackgroundWithContent>
        <Container>
          <Modal 
            loading={modalLoading}
            showModal={modal}
            title={modalTitulo}
            text={modalMessage}
            isButton={modalButton}
            onClose= {() => {
              setModal(false);
            }}
          />
          <div className="fixed">
            <NavBar 
              title="Lançar comanda"
              navigateTo="/principal"
            />
            <div className="cabecalho"> 
                <div>
                    <div className="comanda"> 
                        <label> Comanda </label>
                        <div className="destaque" 
                          onClick={() => dispatch(CarrinhoActions.limparSocio())}
                        > {carrinho.socio.codigo_carteirinha} </div>
                    </div>
                    <div className="socio"> 
                        <label>Sócio</label>
                        <div> {carrinho.socio.nome} </div>
                    </div>
                </div>
                <Badge 
                    icon={<FaShoppingCart size={20} color="white"/>}
                    bgColor="#517fA4"
                    badgeColor="green"
                    value={carrinho.produtos.length}
                    onClick={handleCarrinho}
                />
            </div>
            <div className="searchBox">
                <TInput 
                    icon={<FaSearch />}
                    placeholder="Digite o código ou o modelo do produto"
                    styles={{
                        height: "40px"
                    }}
                    
                    value={procura}
                    onChange={(e: any) => setProcura((e.target.value).toUpperCase())}
                />
            </div>  
            {categorias.length > 0 && (
              <div className="categorias">
                  {categorias.map((item: any, index: number) => {
                      return (
                          <Categoria 
                            key={index}
                            item={item}
                            onClick={() => handleSelecionarCategoria(item)}
                            marcado={item.id === categoriaSelecionada}
                          />
                      )
                  })}
              </div>
            )}
          </div>
          {produtos.length > 0 ? (
            <div className="produtos">
                {produtos.map((item, index) => {
                  return (
                    <ProdutoBox 
                      key={index}
                      index={index}
                      item={item}
                      onClick={() => handleAdicionarCarrinho(item)}
                    />
                  )
                })}
            </div>
          ) : (
            <div className="empty"> Não foram encontrados produtos para essa categoria. </div>
          )}
          <div className="bottomFixed"> 
            <TButton 
              title={"Limpar carrinho"}
              onClick={handleLimparCarrinho}
              styles={{
                textColor: "white"
              }}
            />
          </div>
        </Container>
      </BackgroundWithContent>
    )
}