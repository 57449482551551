import styled from "styled-components";

const Overlay = styled.div`
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(190, 183, 183, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;

    border-top-left-radius: 3px;
    border-top-right-radius: 3px;

    /* border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px; */

    .container {
        width: 90%;
        max-width: 500px;
        background-color: ${({theme}) => theme.bgColor};

        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
        border-bottom-left-radius: 3px;
        border-bottom-right-radius: 3px;
    }

    .modalHeader {
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
        background-color: ${({theme}) => theme.titleBGColor};
        padding: 10px;

        h4 {
            margin: 0;
            color: ${({theme}) => theme.titleColor};
            font-weight: bold;
        }
    }

    .modalBody {
        margin-bottom: 5px;
        margin: 14px 10px 10px 10px;

        color: ${({theme}) => theme.textColor};
    }

    .loading {
        display: flex;
        align-self: center;
        align-items: center;
        justify-content: center;
        margin-bottom: 5px;
    }   

    /* buttonBGColor: colors.fundobotao,
    buttonColor: colors.textobotao, */

    .buttonModal {
        background-color: ${({theme}) => theme.buttonBGColor};
        color: ${({theme}) => theme.buttonColor};

        display: flex;

        align-self: center;
        align-items: center;
        justify-content: center;
        text-align: center;
        
        width: 35%;
        height: 40px;

        margin: 5px auto;
        

        border: 1px solid transparent;
    }
`

const Container = styled.div`
    width: 500px;
    background-color: black;
`

export { Overlay, Container }