import styled  from "styled-components";

const Container = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;

    padding: 0px 10px;

    align-items: center;
    align-self: center;

    text-align: right;    
    font-size: 1em;
    font-weight: bold;
    color: #3D3D46;

    position: static;

    svg {
        margin-right:  35px;
        width: 17.5px;
        height: auto;
    }

    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`

export { Container }