export function loginSucesso(login) {
  return {
    type: '@usuario/LOGIN_SUCESSO',
    payload: login,
  };
}

export function loginFalha() {
  return {
    type: '@usuario/LOGIN_FALHA',
    payload: {},
  };
}

export function logout() {
  return {
    type: '@usuario/LOGOUT',
    payload: {},
  };
}

export function validarToken(token) {
  return {
    type: '@usuario/VALIDAR_TOKEN',
    payload: {
      token,
    },
  };
}
