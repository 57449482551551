
import { ReactElement } from "react";
import { Overlay } from "./styled";

import { Spinner } from "react-activity";
import "react-activity/dist/library.css";

import Cores from '@/styles/colors'
const colors = Cores.modal

type ModalTypes = {
    title?: ReactElement | string,
    text?: string,
    showModal?: boolean,
    loading ?: boolean,
    isButton?: boolean,
    buttonText?: string,
    onClose?: React.MouseEventHandler<any>,
    onClick?: React.MouseEventHandler<any>,
    styles?: StylesTypes
}

interface StylesTypes {
    bgColor?: string,
    titleBGColor?: string,
    titleColor?: string,
    textColor?: string,
    buttonBGColor?: string,
    buttonColor?: string,
    loadingColor?: string
}

const defaultModalProps = {
    title: "Aviso",
    buttonText: "OK",
    showModal: false,
    onClick: () => {},
    onClose: () => {},
    loading: false,
    isButton: true,
    styles: {
        bgColor: colors.fundo,
        titleBGColor: colors.fundotitulo,
        titleColor: colors.titulo,
        textColor: colors.texto,
        buttonBGColor: colors.fundobotao,
        buttonColor: colors.textobotao,
        loadingColor: colors.loadingColor
    }
}


function Modal({
    showModal,
    title,
    text,
    onClose,
    loading,
    buttonText,
    isButton,
    styles
}: ModalTypes) {

    if (!showModal) {
        return null
    }

    return (
        <Overlay 
            onClick={onClose}
            theme={styles}
        >
            <div className="container" onClick={e => e.stopPropagation()}>
                <div className="modalHeader">
                    <h4> {title} </h4>
                </div>
                <div className="modalBody">
                    {text}
                </div>
                {loading && (
                    <div className="loading">
                        <Spinner 
                            color={styles?.loadingColor}
                            size={20}
                            style={{margin: 10}}
                        />
                    </div>
                )}
                {isButton && (
                    <button className="buttonModal" onClick={onClose}> {buttonText} </button> 
                )}
            </div>
        </Overlay>
    )
}

export default Modal

Modal.defaultProps = defaultModalProps