import axios from 'axios'
import storage from 'redux-persist/lib/storage';

const axiosClient = async () => {
  const baseUrl = await storage.getItem('baseURL') ?? undefined
  return axios.create({
    baseURL: baseUrl,
    timeout: 5000    
  })
}

export const promiseTimeout = async (request: Promise<any>): Promise<any> => {
  const timeout = new Promise(async (resolve, reject) => {  
    setTimeout(reject, 5000, { error: { msg: 'Não foi possível se conectar com a API', errorID: "FRSERV001" } })
  })

  return Promise.race([request, timeout]).then(
    (response: any) => {
      return response.data
    },
    (err) => {
        // Possui error ID (erro "tratado" pela API)
        if (err.response) {
          if (!err.response.data) {
            return {
              msg: "Erro de comunicação com a API",
              errorID: "B1TESTEINT"
            }
          }

          return {
            msg: err.response.data.message,
            errorID: "B1TESTEINT"
          }
          
        } else if (err.request) {
          return {
            msg: "Erro interno da API",
            errorID: "B1SERV002"
          }
        } else {
          return {
            msg: "Erro interno de comunicação",
            errorID: "B1SERV003"
          }
        }
    }
  )
}

export default axiosClient



