import styled from "styled-components";

const Container = styled.div`


    .infos {
        display: flex;
        flex-direction: column;
        background-color: #EAEBED;
        padding: 5px;

        > div {
            display: flex;
            flex-direction: row;
            align-items: center;

            > label {
                font-weight: bold;
                margin-right: 5px;
            }

            > p {
                margin: 0;
            }

            :nth-child(2) {
                margin: 5px 0px;
            }
        }
    }

    .selectedItens {
        font-size: 1.25em;
        
        span {
            font-weight: bold;
        }
    }

    .infos, .selectedItens, .empty {
        margin: 10px;
    }

    .empty {
        font-size: 0.75em;
    }

    .produtos {
        padding: 0px 10px;
        padding-bottom: 95px;
        
        > div {
            margin-bottom: 10px;
        }
    }

    .totalContainer {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        margin-bottom: 10px;
        
        label {
            font-weight: bold;
        }

        p {
            margin: 0;
        }
    }

    .bottomFixed {
        padding: 10px;
        width: 100%;

        background-color: white;
        position: fixed;
        bottom: 0;
        
    }
`

export { Container }